<!-- <template>
  <div class="map-view">
    <div id="main"></div>
  </div>
</template>

<script>
  import geoJson from '@/assets/china.json'
  // import {travel} from '@/api/api'
  import axios from 'axios'
  // import { getToken} from '../../utils/setToken'
export default {
  data(){
    return{

    }
  },
async  created(){
// travel().then(res=>{
//   if(res.data.status===200){
//     let {points,linesData}=res.data.data
//     console.log(points)
//     this.draw(points,linesData)
//   }
// })

let res= await axios.get('http://www.zjgcpt.cn:9999/gps-web/api/login.jsp?password=554b4e7dcedaaa04a416ef03a48ace98&onlyValidate=&plateColor=&userId=qnzj&loginType=user&loginWay=interface&loginLang=zh_CN')
// .then(res => {

// setToken('id', res.data.sessionId)
// sess=res.data.sessionId

// })

axios.get('http://www.zjgcpt.cn:9999/gps-web/api/get_gps_r.jsp?teamId=&carIds=&sessionId='+res.data.sessionId)
    .then(res=>{
      let points=[]
      let linesData=[]
      res.data.list.forEach(item=>{
        

        points.push({value:[item.lng,item.lat],itemStyle:{color:`rgb(${Math.round(Math.random()*255)},${Math.round(Math.random()*255)},${Math.round(Math.random()*255)})`}})
        linesData.push({coords:[[item.lng,item.lat],[107.496831,26.373042]],lineStyle:{color:`rgb(${Math.round(Math.random()*255)},${Math.round(Math.random()*255)},${Math.round(Math.random()*255)})`}})
        
      })
      
      this.draw(points,linesData)
    })
  },
  methods:{
    draw(points,linesData){
      let myChart = this.$echarts.init(document.getElementById('main'))
    this.$echarts.registerMap('贵州',geoJson)
    let option={
      backgroundColor:'rgb(121,145,209)',
      geo:{
        map:'贵州',
        aspectScale:0.75,
        zoom:1.1,
        itemStyle:{
          normal:{
            areaColor:{
              type:'radial',
              x:0.5,
              y:0.5,
              r:0.8,
              colorStops:[
                {
                  offset:0,
                  color:'#09132c'
                },
                {
                  offset:1,
                  color:'#274d68'
                }
              ],
              globalCoord:true
            },
            shadowColor:'rgb(58,115,192)',
            shadowOffsetX:10,
            shadowOffsetY:10
          }
        }
        // regions:[{
        //   name:'南海诸岛'
        // }]
      },
      series:[
        {
          type:'map',
          label:{
            normal:{
              show:true,
              textStyle:{
                color:'#1DE9B6'
              }
            },
            emphasis:{
              textStyle:{
                color:'rgb(183,185,14)'
              }
            }
          },
          zoom:1.1,
          map:'贵州',
          itemStyle:{
          normal:{
            backgroundColor:'rgb(147,235,248)',
            borderWidth:1,
            areaColor:{
              type:'radial',
              x:0.5,
              y:0.5,
              r:0.8,
              colorStops:[
                {
                  offset:0,
                  color:'rgb(31,54,150)'
                },
                {
                  offset:1,
                  color:'rgb(89,128,142)'
                }
              ],
              globalCoord:true
            }
          },
          emphasis:{
            areaColor:'rgb(46,229,206)',
            borderWidth:0.1
          }
        }
        },
        {
          type:'effectScatter',
          coordinateSystem:'geo',
          showEffectOn:'render',
          symbolSize:10,
          zlevel:1,
          data:points,
          rippleEffect:{
            period:15,
            scale:4,
            brushType:'fill'
          }
        },
        {
          type:'lines',
          zlevel:2,
          effect:{
            show:true,
            period:4,
            symbol:'arrow',
            symbolSize:7,
            trailLength:0.4,

          },
          lineStyle:{
            normal:{
              color:'#1DE9B6',
              width:1,
              opacity:0.1,
              curveness:0.3
            }
          },
          data:linesData       
        }
      ]
    }
    
    myChart.setOption(option)
    // console.log(geoJson)
    myChart.on("click", param => {
        console.log(param)
      });
    }
 
    
  }
}
</script>

<style lang="scss">
  .map-view{
    width:100%;
    #main{
      width: 100%;
      height: 800px;
    }
  }

</style> -->
<template>
  <div>
    <div class="deng-box1">
    <div class="deng">
        <div class="xian"></div>
        <div class="deng-a">
            <div class="deng-b"><div class="deng-t">财</div></div>
        </div>
        <div class="shui shui-a"><div class="shui-c"></div><div class="shui-b"></div></div>
    </div>
</div>
<div class="deng-box2">
    <div class="deng">
        <div class="xian"></div>
        <div class="deng-a">
            <div class="deng-b"><div class="deng-t">源</div></div>
        </div>
        <div class="shui shui-a"><div class="shui-c"></div><div class="shui-b"></div></div>
    </div>
</div>
<div class="deng-box3">
    <div class="deng">
        <div class="xian"></div>
        <div class="deng-a">
            <div class="deng-b"><div class="deng-t">进</div></div>
        </div>
        <div class="shui shui-a"><div class="shui-c"></div><div class="shui-b"></div></div>
    </div>
</div>
<div class="deng-box4">
    <div class="deng">
        <div class="xian"></div>
        <div class="deng-a">
            <div class="deng-b"><div class="deng-t">广</div></div>
        </div>
        <div class="shui shui-a"><div class="shui-c"></div><div class="shui-b"></div></div>
    </div>
</div>
<canvas id="c" ref="c" style="position: absolute;z-index: -1;text-align: center;">div</canvas>


  </div>
  
</template>
<!-- <script src="~@/assets/js/jquery.min.js"></script> -->
<script src="http://www.jq22.com/jquery/jquery-1.10.2.js"></script> 
<script src="../../assets/js/canvas_bg.js"></script>
<script>

 
export default {
  data() {
    return {
      
    };
  },
  mounted () {
        // 获取canvas实例
        const canvas = this.$refs.c
        // 创建context对象
        const ctx = canvas.getContext('2d')
        ctx.fillStyle = '#FF0000';
    ctx.fillRect(100, 10, 100, 100);
    },
  methods: {
    
  }
};
</script>
<style  >
  .deng-box1 {
        position: fixed;
        top: 100px;
        left: 150px;
        z-index: 9999;
        pointer-events: none;
    }
     
    .deng-box2 {
        position: fixed;
        top: 105px;
        left: 220px;
        z-index: 9999;
        pointer-events: none;
    }
    
    .deng-box3 {
        position: fixed;
        top: 100px;
        right: 100px;
        z-index: 9999;
        pointer-events: none;
    }
     
    .deng-box4 {
        position: fixed;
        top: 105px;
        right: 170px;
        z-index: 9999;
        pointer-events: none;
    }
    
    .deng-box1 .deng {
        position: relative;
        width: 120px;
        height: 90px;
        margin: 50px;
        background: #d8000f;
        background: rgba(216, 0, 15, 0.8);
        border-radius: 50% 50%;
        -webkit-transform-origin: 50% -100px;
        -webkit-animation: swing 3s infinite ease-in-out;
        box-shadow: -5px 5px 50px 4px rgba(250, 108, 0, 1);
    }
    
    .deng-box2 .deng {
        position: relative;
        width: 120px;
        height: 90px;
        margin: 50px;
        background: #d8000f;
        background: rgba(216, 0, 15, 0.8);
        border-radius: 50% 50%;
        -webkit-transform-origin: 50% -100px;
        -webkit-animation: swing 4s infinite ease-in-out;
        box-shadow: -5px 5px 30px 4px rgba(252, 144, 61, 1);
    }
    
    .deng-box3 .deng {
        position: relative;
        width: 120px;
        height: 90px;
        margin: 50px;
        background: #d8000f;
        background: rgba(216, 0, 15, 0.8);
        border-radius: 50% 50%;
        -webkit-transform-origin: 50% -100px;
        -webkit-animation: swing 5s infinite ease-in-out;
        box-shadow: -5px 5px 50px 4px rgba(250, 108, 0, 1);
    }
    
    .deng-box4 .deng {
        position: relative;
        width: 120px;
        height: 90px;
        margin: 50px;
        background: #d8000f;
        background: rgba(216, 0, 15, 0.8);
        border-radius: 50% 50%;
        -webkit-transform-origin: 50% -100px;
        -webkit-animation: swing 4s infinite ease-in-out;
        box-shadow: -5px 5px 30px 4px rgba(252, 144, 61, 1);
    }
    
    .deng-a {
        width: 100px;
        height: 90px;
        background: #d8000f;
        background: rgba(216, 0, 15, 0.1);
        margin: 12px 8px 8px 10px;
        border-radius: 50% 50%;
        border: 2px solid #dc8f03;
    }
    
    .deng-b {
        width: 45px;
        height: 90px;
        background: #d8000f;
        background: rgba(216, 0, 15, 0.1);
        margin: -2px 8px 8px 26px;
        border-radius: 50% 50%;
        border: 2px solid #dc8f03;
    }
    
    .xian {
        position: absolute;
        top: -50px;
        left: 60px;
        width: 2px;
        height: 50px;
        background: #dc8f03;
    }
    
    .shui-a {
        position: relative;
        width: 5px;
        height: 20px;
        margin: -5px 0 0 59px;
        -webkit-animation: swing 4s infinite ease-in-out;
        -webkit-transform-origin: 50% -45px;
        background: #ffa500;
        border-radius: 0 0 5px 5px;
    }
    
    .shui-b {
        position: absolute;
        top: 14px;
        left: -2px;
        width: 10px;
        height: 10px;
        background: #dc8f03;
        border-radius: 50%;
    }
    
    .shui-c {
        position: absolute;
        top: 18px;
        left: -2px;
        width: 10px;
        height: 35px;
        background: #ffa500;
        border-radius: 0 0 0 5px;
    }
    
    .deng:before {
        position: absolute;
        top: -7px;
        left: 29px;
        height: 12px;
        width: 60px;
        content: " ";
        display: block;
        z-index: 999;
        border-radius: 5px 5px 0 0;
        border: solid 1px #dc8f03;
        background: #ffa500;
        background: linear-gradient(to right, #dc8f03, #ffa500, #dc8f03, #ffa500, #dc8f03);
    }
    
    .deng:after {
        position: absolute;
        bottom: -7px;
        left: 10px;
        height: 12px;
        width: 60px;
        content: " ";
        display: block;
        margin-left: 20px;
        border-radius: 0 0 5px 5px;
        border: solid 1px #dc8f03;
        background: #ffa500;
        background: linear-gradient(to right, #dc8f03, #ffa500, #dc8f03, #ffa500, #dc8f03);
    }
    
    .deng-t {
        font-family: 华文行楷,Arial,Lucida Grande,Tahoma,sans-serif;
        font-size: 3.2rem;
        color: #dc8f03;
        font-weight: bold;
        line-height: 85px;
        text-align: center;
    }
    
    .night .deng-t, 
    .night .deng-box, 
    .night .deng-box1 {
        background: transparent !important;
    }
    
    @-moz-keyframes swing {
        0% {
            -moz-transform: rotate(-10deg)
        }
    
        50% {
            -moz-transform: rotate(10deg)
        }
     
        100% {
            -moz-transform: rotate(-10deg)
        }
    }
    
    @-webkit-keyframes swing {
        0% {
            -webkit-transform: rotate(-10deg)
        }
    
        50% {
            -webkit-transform: rotate(10deg)
        }
    
        100% {
            -webkit-transform: rotate(-10deg)
        }
    }

</style>